import React from 'react';
import {Link, NavLink} from "react-router-dom";

import ExternalLink from "./ExternalLink";

import SocialIconTwitter from "./svgs/SocialIconTwitter";
import SocialIconFacebook from "./svgs/SocialIconFacebook";
import SocialIconPatreon from "./svgs/SocialIconPatreon";

export default function NavigationBar() {

    return (
        <header className="header">
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row"
            }}>
                <Link className="logo" to="/" title="Back to Home">
                    <img height={40} width={45} src="/android-chrome-512x512.png" style={{marginRight: 12}} alt="" />
                    <span style={{
                        letterSpacing: "-2px"
                    }}>
                        ARIES
                    </span>
                    <span style={{
                        fontSize: "0.55em",
                        paddingLeft: "0.3em",
                        color: "#919191",
                        letterSpacing: "-1px"
                    }}>
                        for Transit
                    </span>
                </Link>
            </div>

            <input className="menu-btn" type="checkbox" id="menu-btn"/>
            <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"/></label>
            <ul className="menu">
                <div style={{width: 20}}/>
                <li>
                    <NavLink
                        style={({ isActive }) => ({ color: isActive ? '#40c4ff' : undefined })}
                        to="/trends"
                        title="Trends"
                    >
                        Trends
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        style={({ isActive }) => ({ color: isActive ? '#40c4ff' : undefined })}
                        to="/reports"
                        title="Reports"
                    >
                        Reports
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        style={({ isActive }) => ({ color: isActive ? '#40c4ff' : undefined })}
                        to="/faq"
                        title="Frequently Asked Questions"
                    >
                        FAQ
                    </NavLink>
                </li>
                <li>
                    <ExternalLink url="https://twitter.com/dcmetrohero" title="MetroHero's Twitter Page">
                        <SocialIconTwitter/>
                    </ExternalLink>
                </li>
                <li>
                    <ExternalLink url="https://www.facebook.com/dcmetrohero" title="MetroHero's Facebook Page">
                        <SocialIconFacebook/>
                    </ExternalLink>
                </li>
                <li>
                    <ExternalLink url="https://www.patreon.com/metrohero" title="MetroHero's Patreon Page">
                        <SocialIconPatreon/>
                    </ExternalLink>
                </li>
            </ul>
        </header>
    );
}
