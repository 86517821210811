import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom";

import ExternalLink from "./ExternalLink";
import FAQ from "./FAQ";
import NavigationBar from "./NavigationBar";
import Systems from "./Systems";
import SystemRoutes from "./SystemRoutes";
import Trends from "./Trends";
import Reports from "./Reports";
import VehicleHistory from "./VehicleHistory";
import StopHistory from "./StopHistory";

import BusAndStopGraphic from "./svgs/BusAndStopGraphic";

import './sass/main.scss';

export default function App() {
    return (
        <Router>
            <div
                className="App"
            >
                <NavigationBar />

                <div className="main-content">
                    <Routes>
                        <Route path="/systems/:systemName/routes" element={<SystemRoutes />} />
                        <Route path="/faq" element={<FAQ />} />
                        <Route path="/trends" element={<Trends />} />
                        <Route path="/reports" element={<Reports />} />
                        <Route path="/history/vehicle" element={<VehicleHistory />} />
                        <Route path="/history/stop" element={<StopHistory />} />
                        <Route path="/" element={<Systems />} />
                    </Routes>
                </div>

                <div className="footer">
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: 24
                        }}
                    >
                        <BusAndStopGraphic />
                    </div>
                    <div className="footer-text">
                        <div>
                        <span>
                            made with nerdiness by&nbsp;
                        </span>
                        <span>
                            the team at <ExternalLink url="https://www.dcmetrohero.com/">MetroHero</ExternalLink>
                        </span>
                        </div>

                        <div style={{margin: '0.5em 0 1em 0', fontSize: '0.9em'}}>
                            <ExternalLink url="https://twitter.com/dcmetrohero" title="MetroHero's Twitter Page">Twitter</ExternalLink>&nbsp;·&nbsp;
                            <ExternalLink url="https://www.facebook.com/dcmetrohero" title="MetroHero's Facebook Page">Facebook</ExternalLink>&nbsp;·&nbsp;
                            <ExternalLink url="https://www.patreon.com/metrohero" title="MetroHero's Patreon Page">Patreon</ExternalLink>
                        </div>
                    </div>
                </div>
            </div>
        </Router>
    );
}
