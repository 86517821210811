import moment from "moment";
import React, {useEffect, useRef, useState} from "react";
import Dropdown from "react-dropdown";
import ReactGA from "react-ga4";
import {Link, useNavigate} from "react-router-dom";

import MHTooltip from "./MHTooltip";
import PageLoadingSpinner from "./PageLoadingSpinner";
import OperationalPerformanceChart from "./OperationalPerformanceChart";
import ExternalLink from "./ExternalLink";

const DEFAULT_ROUTE_ID = '(all)';
const DEFAULT_ROUTE_IDS = [DEFAULT_ROUTE_ID];

function _usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

function _getUrl(systemName, routeId, performanceType, graphType, type) {
    const params = {};

    if (systemName) {
        params["systemName"] = encodeURIComponent(systemName);
    }

    if (routeId) {
        params["routeId"] = encodeURIComponent(routeId);
    }

    if (performanceType) {
        params["performanceType"] = encodeURIComponent(performanceType);
    }

    if (graphType) {
        params["graphType"] = encodeURIComponent(graphType);
    }

    if (type) {
        params["type"] = encodeURIComponent(type);
    }

    const paramsArray = Object.keys(params).map((paramName) => {
        const paramValue = params[paramName];
        return `${paramName}=${paramValue}`;
    });

    return `${window.location.pathname}?${paramsArray.join("&")}`;
}

const GRAPH_TYPE_OPTIONS = [
    { label: 'Hour', value: 'past-hour' },
    { label: '3 hours', value: 'past-3-hours' },
    { label: '6 hours', value: 'past-6-hours' },
    { label: '12 hours', value: 'past-12-hours' },
    { label: '24 hours', value: 'past-24-hours' },
    { label: 'Week (by day)', value: 'day-over-week' },
    { label: 'Month (by day)', value: 'day-over-month' },
    { label: 'Month (by week)', value: 'week-over-month' },
    { label: 'Quarter (by week)', value: 'week-over-quarter' },
    { label: 'Quarter (by month)', value: 'month-over-quarter' },
    { label: 'Year (by month)', value: 'month-over-year' }
];

export default function Trends() {
    const navigate = useNavigate();

    const params = new URLSearchParams(document.location.search.substring(1));

    const [lastUpdated, setLastUpdated] = useState(null);
    const [rawPerformanceData, setRawPerformanceData] = useState(null);
    const [routeIds, setRouteIds] = useState(DEFAULT_ROUTE_IDS);

    const [systemName, setSystemName] = useState(params.get("systemName") || "WMATA Metrobus");
    const [routeId, setRouteId] = useState(params.get("routeId") || DEFAULT_ROUTE_ID);
    const [performanceType, setPerformanceType] = useState(params.get("performanceType") || "recent");
    const [graphType, setGraphType] = useState(params.get("graphType") || "past-3-hours");
    const [type, setType] = useState(params.get("type") || "otp");

    useEffect(() => {
        // reset scroll
        window.scrollTo(0, 0);

        // on mount
        ReactGA.initialize('G-ZN33QTNMS7');
        ReactGA.pageview(window.location.pathname);

        return () => {
            // on unmount
            const timerId = localStorage.getItem("timerId");
            if (timerId) {
                clearTimeout(parseInt(timerId, 10));
            }
        };
    }, []);

    const previousSystemName = _usePrevious(systemName);
    useEffect(() => {
        const startMillis = (new Date()).getTime();

        setLastUpdated(null);
        setRawPerformanceData(null);

        if (previousSystemName && previousSystemName !== systemName) {
            // selected system changed, so reset the selected route ID
            setRouteIds(DEFAULT_ROUTE_IDS);
            setRouteId(DEFAULT_ROUTE_ID);
        }

        async function fetchData() {
            let refreshInterval;
            try {
                const timerId = localStorage.getItem("timerId");
                if (timerId) {
                    clearTimeout(parseInt(timerId, 10));
                }

                const url = `/api/v1/system/${encodeURIComponent(systemName)}/performance/${performanceType}?${graphType ? `graphType=${graphType}&` : ''}${type ? `type=${type}&` : ''}shouldIncludeRouteData=true`;
                const res = await fetch(url);
                const text = await res.text();

                let json;
                try {
                    json = JSON.parse(text);
                } catch (e) {
                    console.debug(`Response body from GET ${url} failed to be parsed:`, e);
                }

                const endMillis = (new Date()).getTime();

                setTimeout(() => {
                    if (json) {
                        const newLastUpdated = json['lastUpdated'];
                        const newRawPerformanceData = (routeId === DEFAULT_ROUTE_ID) ? json : json['routes'][routeId];
                        const newRouteIds = DEFAULT_ROUTE_IDS.concat(Object.keys(json['routes']));

                        setLastUpdated(newLastUpdated);
                        setRawPerformanceData(newRawPerformanceData);
                        setRouteIds(newRouteIds);
                    } else {
                        setLastUpdated(null);
                        setRawPerformanceData(undefined);
                        setRouteIds(DEFAULT_ROUTE_IDS);
                    }
                }, Math.max(500 - (endMillis - startMillis), 0));  // make sure users always see the loading spinner, but not more than they need to
            } catch (err) {
                refreshInterval = 5000;
            } finally {
                if (refreshInterval) {
                    const timerId = setTimeout(fetchData, refreshInterval, true);
                    localStorage.setItem("timerId", timerId.toString());
                }
            }
        }

        navigate(_getUrl(systemName, routeId, performanceType, graphType, type), { replace: true });

        setTimeout(fetchData, 0);
    }, [systemName, routeId, performanceType, graphType, type]);

    let lastUpdatedContent;
    if (lastUpdated) {
        lastUpdatedContent = (
            <div
                style={{
                    display: 'inline-block',
                    fontSize: 12,
                    marginBottom: '2em',
                    color: '#666666'
                }}
            >
                <span
                    style={{
                        display: 'inline-block'
                    }}
                >
                    last updated&nbsp;
                </span>
                <span
                    style={{
                        display: 'inline-block'
                    }}
                >
                    <strong>{moment(lastUpdated).toString()}</strong>
                </span>
            </div>
        );
    }

    const localFooter = (
        <div
            className="local-footer"
        >
            <em>Psst!</em> Interested in digging into the data even deeper?<br/>
            Check out our interactive <Link to={`/history/vehicle?systemName=${encodeURIComponent(systemName)}`}>Vehicle History</Link> and <Link to={`/history/stop?systemName=${encodeURIComponent(systemName)}`}>Transit Stop History</Link> tools.
        </div>
    );

    let pageContent;
    if (rawPerformanceData === null) {
        pageContent = (
            <PageLoadingSpinner />
        );
    } else if (rawPerformanceData === undefined) {
        let noDataAvailableForNoun;
        if (routeId === DEFAULT_ROUTE_ID) {
            noDataAvailableForNoun = systemName;
        } else {
            noDataAvailableForNoun = `route ${routeId}`;
        }

        pageContent = (
            <>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 230
                    }}
                >
                    <div>
                        <div>
                            No data available for {noDataAvailableForNoun} over the past {GRAPH_TYPE_OPTIONS.find(option => option.value === graphType).label.split(' (')[0].toLowerCase()}!
                        </div>
                        <div
                            style={{
                                marginTop: 6
                            }}
                        >
                            Choose a different route or time scale, or try again later.
                        </div>
                    </div>
                </div>
                {localFooter}
            </>
        );
    } else {
        pageContent = (
            <>
                <OperationalPerformanceChart
                    data={rawPerformanceData}
                    shouldIncludeSystemAndRouteName={true}
                    type={type}
                />
                {localFooter}
            </>
        );
    }

    return (
        <div
            className="Trends container"
            style={{
                position: 'relative',
                height: '100%',
                textAlign: 'center'
            }}
        >
            <div
                style={{
                    display: 'inline-block'
                }}
            >
                <div
                    style={{
                        display: 'inline-block',
                        textAlign: 'left',
                        minWidth: 220,
                        maxWidth: 300,
                        margin: '0.5em'
                    }}
                >
                    <div
                        style={{
                            minWidth: '55px',
                            marginRight: '0.5em',
                            marginBottom: '0.5em'
                        }}
                    >
                        Transit system
                    </div>

                    <div
                        className="sortBy-dropdown has-addons"
                    >
                        <Dropdown
                            className="dropdown"
                            options={[
                                { label: 'ART', value: 'ART' },
                                { label: 'DASH', value: 'DASH' },
                                { label: 'Fairfax Connector', value: 'Fairfax Connector' },
                                { label: 'MTA Light RailLink', value: 'MTA Light RailLink' },
                                { label: 'MTA Local Bus', value: 'MTA Local Bus' },
                                { label: 'MTA MARC Train', value: 'MTA MARC Train' },
                                { label: 'MTA Metro SubwayLink', value: 'MTA Metro SubwayLink' },
                                { label: 'Ride On', value: 'Ride On' },
                                { label: 'TheBus', value: 'TheBus' },
                                { label: 'VRE', value: 'VRE' },
                                { label: 'WMATA Metrobus', value: 'WMATA Metrobus' },
                                { label: 'WMATA Metrorail', value: 'WMATA Metrorail' }
                            ]}
                            onChange={data => {
                                setSystemName(data.value);
                            }}
                            value={systemName}
                            placeholder="Select an option"
                        />
                    </div>
                </div>

                <div
                    style={{
                        display: 'inline-block',
                        textAlign: 'left',
                        minWidth: 220,
                        maxWidth: 300,
                        margin: '0.5em'
                    }}
                >
                    <div
                        style={{
                            minWidth: '55px',
                            marginRight: '0.5em',
                            marginBottom: '0.5em'
                        }}
                    >
                        Route
                    </div>

                    <div
                        className="sortBy-dropdown has-addons"
                    >
                        <Dropdown
                            className="dropdown"
                            options={routeIds}
                            onChange={data => {
                                setRouteId(data.value);
                            }}
                            value={routeId}
                            placeholder="Select an option"
                        />
                    </div>
                </div>
            </div>

            <div
                style={{
                    display: 'inline-block'
                }}
            >
                <div
                    style={{
                        display: 'inline-block',
                        textAlign: 'left',
                        minWidth: 220,
                        maxWidth: 300,
                        margin: '0.5em'
                    }}
                >
                    <div
                        style={{
                            minWidth: '55px',
                            marginRight: '0.5em',
                            marginBottom: '0.5em'
                        }}
                    >
                        Performance metric
                        <MHTooltip
                            body={
                                <>
                                    Check out <ExternalLink className="secondary" url="/faq#calculate" style={{textTransform: 'initial', letterSpacing: 'initial'}}>our FAQs</ExternalLink> for more details
                                </>
                            }
                            position='top'
                            interactive
                        />
                    </div>

                    <div
                        className="sortBy-dropdown has-addons"
                    >
                        <Dropdown
                            className="dropdown"
                            options={[
                                { label: 'Trips', value: 'trips' },
                                { label: 'Headways', value: 'headways' },
                                { label: 'On-time performance', value: 'otp' },
                                { label: 'Vehicle crowding', value: 'crowding' },
                                { label: 'Earliness', value: 'earliness' },
                                { label: 'Lateness', value: 'lateness' }
                            ]}
                            onChange={data => {
                                setType(data.value);
                            }}
                            value={type}
                            placeholder="Select an option"
                        />
                    </div>
                </div>

                <div
                    style={{
                        display: 'inline-block',
                        textAlign: 'left',
                        minWidth: 220,
                        maxWidth: 300,
                        margin: '0.5em'
                    }}
                >
                    <div
                        style={{
                            minWidth: '55px',
                            marginRight: '0.5em',
                            marginBottom: '0.5em'
                        }}
                    >
                        Over the past…
                    </div>

                    <div
                        className="sortBy-dropdown has-addons"
                    >
                        <Dropdown
                            className="dropdown"
                            options={GRAPH_TYPE_OPTIONS}
                            onChange={data => {
                                const value = data.value;
                                if (value) {
                                    if (value.startsWith("past-")) {
                                        setPerformanceType('recent');
                                    } else {
                                        setPerformanceType('historical');
                                    }

                                    setGraphType(value);
                                }
                            }}
                            value={graphType}
                            placeholder="Select an option"
                        />
                    </div>
                </div>
            </div>

            <hr
                style={{
                    margin: '1em 0.5em'
                }}
            />

            {lastUpdatedContent}

            {pageContent}
        </div>
    );
}