import React, {useEffect} from "react";
import ReactGA from "react-ga4";
import StickyBox from "react-sticky-box";

import ExternalLink from "./ExternalLink";

export default function FAQ() {

    useEffect(() => {
        // reset scroll
        window.scrollTo(0, 0);

        ReactGA.initialize('G-ZN33QTNMS7');
        ReactGA.pageview(window.location.pathname);
    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="column faq-sidebar" style={{flex: "3 2 0px"}}>
                    <StickyBox offsetTop={20} offsetBottom={20}>
                        <ul>
                            <li><a href="#about">About Us</a></li>
                            <li><a href="#data">Data Sources</a></li>
                            <li><a href="#metrics">Performance Metrics</a></li>
                            <li><a href="#calculate">How We Calculate</a></li>
                            <li><a href="#pointintime">"Point In Time"</a></li>
                            <li><a href="#visits">Stop Visit Detection</a></li>
                        </ul>
                    </StickyBox>
                </div>
                <div className="column" style={{flex: "8 6 0px"}}>
                    <h1>Frequently Asked Questions</h1>

                    <article>
                        <section id="about">
                            <h2>Who are you?</h2>
                            <p>
                                We're <ExternalLink className="normal" url="http://jamespizzurro.com/">James</ExternalLink> and <ExternalLink className="normal" url="https://www.linkedin.com/in/jenpizzurro">Jen</ExternalLink> Pizzurro, two software engineers currently living in Baltimore, MD. Back in September 2015, while we were still commuting from Arlington, VA to Washington, DC, we launched <ExternalLink className="normal" url="https://dcmetrohero.com/dashboard">MetroHero</ExternalLink>, an app designed for WMATA Metrorail commuters and transit wonks that lets you see the position of every Metro train in real time. Since then, we've added performance measuring capabilities to MetroHero that allow you to see how each train station and line is doing. This work inspired us to create a <ExternalLink className="normal" url="/documents/mbrc-report.pdf">Metrobus Report Card</ExternalLink> in 2019, co-authored by the <ExternalLink className="normal" url="https://www.smartergrowth.net/">Coalition for Smarter Growth</ExternalLink>. Using what we learned from making that report card, we created ARIES—the Adherence, Reliability, and Integrity Evaluation System—as an authoritative third-party source of operational performance data on not just Metrobus, but other transit systems in Greater Washington and Baltimore regions as well.
                            </p>
                            <p>
                                If you've got questions beyond what's covered in this FAQ, you can always email us at <ExternalLink className="normal" url="mailto:contact@dcmetrohero.com">contact@dcmetrohero.com</ExternalLink>, tweet or DM us <ExternalLink className="normal" url="https://twitter.com/dcmetrohero" title="MetroHero's Twitter Page">on Twitter</ExternalLink>, or send us a message <ExternalLink className="normal" url="https://www.facebook.com/dcmetrohero" title="MetroHero's Facebook Page">on Facebook</ExternalLink> and one of us will get back to you as soon as possible! We're also <ExternalLink url="https://www.patreon.com/metrohero" title="MetroHero's Patreon Page">on Patreon</ExternalLink> if you'd like to make a monthly donation to our cause.
                            </p>
                        </section>
                        <section id="data">
                            <h2>Where do you get your data from?</h2>
                            <p>
                                Each of the transit agencies that manage the transit systems we support provide public <ExternalLink className="normal" url="https://en.wikipedia.org/wiki/General_Transit_Feed_Specification">GTFS</ExternalLink> and <ExternalLink className="normal" url="https://www.transitwiki.org/TransitWiki/index.php/GTFS-realtime">GTFS-realtime</ExternalLink> Vehicle Position data feeds that we use to calculate all our performance metrics. The schedules for and real-time locations of each vehicle, along with the data that connects the two, are all we use from these feeds. Everything else we compute ourselves.
                            </p>
                            <p>
                                We retrieve data from GTFS feeds at least once a day—usually before 4am, but subject to any guidance provided to us by a given transit agency. We retrieve data from GTFS-realtime feeds every 15 seconds, but some transit agencies update this data more frequently than others, i.e. while we retrieve this data every 15 seconds, a given transit agency may only update it every 30 seconds. Finally, we analyze all this data to produce aggregated "point-in-time" performance reports every 30 seconds.
                            </p>
                        </section>
                        <section id="metrics">
                            <h2>What are your performance metrics based on?</h2>
                            <p>
                                All of our performance metrics are calculated based on what is experienced at <span style={{fontStyle: 'italic'}}>transit stops</span>, not on the vehicles themselves. This is a subtle distinction that makes a huge difference in the way we measure performance: our goal is to try to quantitatively represent the state of a transit system <span style={{fontStyle: 'italic'}}>from the perspective of its riders</span>.
                            </p>
                            <span>
                                To help understand the difference, imagine the following scenario:

                                <div style={{marginLeft: '3em', fontStyle: 'italic'}}>
                                    <p>
                                        A stretch of road between two transit stops, Stop A and Stop B, is undergoing significant construction. A bus hits that stretch of road and is significantly slowed down by the roadwork, inevitably falling behind schedule and arriving at Stop B later than expected. At this point in time, the snapshot of on-time performance at both the bus level and the transit stop level would both reflect poor service.
                                    </p>

                                    <p>
                                        However, once the bus leaves Stop B, it is able to speed up and get back on schedule. Now the snapshots of on-time performance are radically different: from the perspective of the <span style={{fontStyle: 'italic'}}>bus</span>, everything is back to normal, and the bus is operating on time; but from the perspective of a person waiting at Stop B, the last thing they observed was a bus that was not on time, so our metrics continue to reflect poor on-time performance for that stop.
                                    </p>
                                </div>
                            </span>
                            <p>
                                Neither of these approaches to calculating performance is fundamentally better or more correct, they are simply different. We use this method because we believe it serves as a better reflection of what an individual person would be experiencing at any given time while they are waiting for the next vehicle to arrive. Note that this means that our numbers cannot necessarily be directly compared to on-time performance metrics reported elsewhere that calculate at the vehicle level.
                            </p>
                        </section>
                        <section id="calculate">
                            <h2>How do you calculate your performance metrics?</h2>
                            <p>
                                Here is a breakdown of each of our performance metrics and how they are calculated over a given timeframe:
                            </p>
                            <ul>
                                <li>
                                    <span style={{textDecoration: 'underline'}}>Headway Adherence</span>: Percent of scheduled visits to each transit stop that occurred within 3 minutes¹ of the scheduled spacing window of the previous visit. <span style={{fontStyle: 'italic'}}>Higher is better.</span>
                                </li>
                                <li>
                                    <span style={{textDecoration: 'underline'}}>Bunched</span>: Percent of scheduled visits to each transit stop that occurred within 25%² of the scheduled time between the visit and the previous visit to the same transit stop. <span style={{fontStyle: 'italic'}}>Lower is better.</span>
                                </li>
                                <li>
                                    <span style={{textDecoration: 'underline'}}>Over-spaced (aka Lateness by Headway)</span>: Percent of scheduled visits to each transit stop that occurred more than 3 minutes later¹ than the scheduled time between the visit and the previous visit to the same transit stop. <span style={{fontStyle: 'italic'}}>Lower is better.</span>
                                </li>
                                <li>
                                    <span style={{textDecoration: 'underline'}}>Schedule Adherence</span>: Percent of scheduled visits to each transit stop that occurred between 2 minutes early and 7 minutes late¹ compared to the scheduled time of the visit. <span style={{fontStyle: 'italic'}}>Higher is better.</span>
                                </li>
                                <li>
                                    <span style={{textDecoration: 'underline'}}>Ahead (aka Earliness by Schedule)</span>: Percent of scheduled visits to each transit stop that occurred more than 2 minutes earlier¹ than the scheduled time of the visit. <span style={{fontStyle: 'italic'}}>Lower is better.</span>
                                </li>
                                <li>
                                    <span style={{textDecoration: 'underline'}}>Behind (aka Lateness by Schedule)</span>: Percent of scheduled visits to each transit stop that occurred more than 7 minutes later¹ than the scheduled time of the visit. <span style={{fontStyle: 'italic'}}>Lower is better.</span>
                                </li>
                                <li>
                                    <span style={{textDecoration: 'underline'}}>Data Integrity</span>: Percent of transit trips scheduled to have been running that we observed to have actually run. A missing trip can occur when a vehicle is not running when it's scheduled to, or when a vehicle is actually running but is not reporting its position properly due to human error or equipment failure. <span style={{fontStyle: 'italic'}}>Higher is better.</span>
                                </li>
                            </ul>
                            <p>
                                ¹ We use the on-time performance thresholds defined by WMATA for its Metrobus service. See "Metrobus On-Time Performance" in Appendix D of <ExternalLink className="normal" url="https://www.wmata.com/about/records/scorecard/upload/MetroPerformanceReport_Q3FY2020.pdf#page=48">WMATA's Q3 FY20 Metro Performance Report</ExternalLink> for more details.
                                <br/>
                                ² We use the bunching threshold defined by the Bus Turnaround Coalition that's used in all their route-level report cards. Click "See Our Methodology" at the bottom of <ExternalLink className="normal" url="http://busturnaround.nyc/report-cards/">the Bus Turnaround Coalition's "Report Cards" page</ExternalLink> for details.
                            </p>
                        </section>
                        <section id="pointintime">
                            <h2>What is point-in-time performance?</h2>
                            <p>
                                The "point-in-time" versions of each of our performance metrics are calculated based on the most recent scheduled visit by a transit vehicle to each transit stop: either an <span style={{fontStyle: 'italic'}}>observed</span> visit, or a visit that was <span style={{fontStyle: 'italic'}}>scheduled to occur</span> but was <span style={{fontStyle: 'italic'}}>not</span> observed. Note that this calculation is technically always backdated by 7 minutes, because, as described in the previous section, a vehicle is not officially considered to be late until it has arrived more than 7 minutes after its scheduled visit time. (e.g. You cannot know the on-time performance of the system as it was at 10:00 AM until 10:07 AM.)
                            </p>
                            <p>
                                Therefore, our point-in-time performance metrics for a given system or route are an aggregate of how many of its transit stops are currently adhering to that performance metric. These values can (and do!) fluctuate regularly, because any given stop has the potential to independently switch between adhering and not adhering as vehicles progress on their scheduled trips and override any previous visits to the stops on those trips; the fewer stops a route has, the more susceptible it is to larger, less gradual fluctuations in its point-in-time performance metrics as there are simply fewer data points to base those metrics on at any given time.
                            </p>
                        </section>
                        <section id="visits">
                            <h2>How do you determine when a vehicle actually visits a transit stop?</h2>
                            <p>
                                This is surprisingly not straightforward!
                            </p>
                            <p>
                                Vehicle positions reported by GPS are not 100% accurate, especially not at such a fine-grained level that we can definitively say when a vehicle has reached a specific point on its travel path. Thus, we consider a vehicle to have visited a stop if we observe it passing within 30 meters of that stop while traveling on its scheduled path.
                            </p>
                            <p>
                                If a transit stop visit happens in the time between two GPS location updates—in other words, if we only observe a vehicle's position before the stop and then its position after the stop—we estimate when the stop visit occurred based on the vehicle's average travel speed and its distance from the stop at the observed before and after points.
                            </p>
                            <p>
                                There are several known drawbacks to the approach we take to determine whether a vehicle has visited a given transit stop or not:
                            </p>
                            <ul>
                                <li>
                                    Transit service providers don't have a standardized way of reporting when vehicle doors are open or closed, which means we aren't able to definitively know if a vehicle actually <span style={{fontStyle: 'italic'}}>serviced</span> a stop we observed it visiting (in other words, if it actually stopped and picked up passengers). In practice, vehicle operators sometimes skip stops for any number of reasons, but this is not something we can observe with the current data.
                                </li>
                                <li>
                                    We can't always accurately measure transit stop visits at the first and last stops in a given trip for a number of reasons; for example, some bus trips start and/or end in a large bus bay which does not have an obvious coordinate point to measure against. Because our measurements are often unreliable at these stops, we do not use data from the first and last transit stops of <span style={{fontStyle: 'italic'}}>any</span> trip in any of our performance metrics. This also means we are unable to measure the performance of any transit routes that only have two stops, e.g. express routes that always go from one place to another without servicing any other transit stops along the way.
                                </li>
                            </ul>
                            <p>
                                Due to all of these difficulties and our heuristic approach of detecting transit stop visits, it is possible that we might erroneously filter out visits that actually did occur. To account for this possibility, where possible, we include <strong>Optimistic</strong> and <strong>Pessimistic</strong> versions of our metrics, where Optimistic assumes that every one of the scheduled transit stops that our system didn't observe actually <span style={{fontStyle: 'italic'}}>did</span> occur (and that they occurred perfectly on time), and Pessimistic assumes the opposite. We use these versions to define a margin of error for our observed metrics, accounting for imperfections in our Data Integrity: although we can never say with perfect certainty how the system is behaving in the real world, we can trust that the true real-world performance of the system falls somewhere between Optimistic and Pessimistic.
                            </p>
                        </section>
                    </article>
                </div>
            </div>
        </div>
    );
}
